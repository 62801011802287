
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.DoYouKnowWrapper {
    width: 100%;
    @include xxl {
        @apply text-4xl;
    }
}
.brief {
    color: #9b9b9b;
}
.title2 {
    color: #000;
}
.title {
}
